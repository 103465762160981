import { useQuery } from '@apollo/client'
import { Button, OrderPriceList, StatusIcon } from '@elements'
import { golfClub as graphqlGolfClub, golfEquipment as graphqlGolfEquipment, user as graphqlUser } from '@graphql'
import { useWindowSize } from '@hooks'
import { AccountDetailsLayout, AccountLayout, MobileAccountDetailsLayout } from '@layouts'
import { RouteComponentProps, useLocation } from '@reach/router'
import { AddressCard, CacheUser, GolfClub, GolfEquipment, ProductPageItemType, ResponseType, TableColumnType } from '@types'
import { formatDateTimeString, getGolfClubInfoByCategory } from '@utils'
import { navigate } from 'gatsby'
import React from 'react'
import './details.scss'

interface AccountMyGolfClubInventoryDetailsProps extends RouteComponentProps<{ golfItemId: string }> { }

const renderStatus = (status: string) => {
  switch (status) {
    case 'ON_SALE':
      return <StatusIcon status={'For Sale'} />
    case 'SOLD':
      return <StatusIcon status={'Sold'} />
    case 'UNSOLD':
      return <StatusIcon status={'Expired'} />
    case 'WAIT_FOR_PAYMENT':
      return <StatusIcon status={'in Processing'} />
    case 'TAXES_PAYMENT_ERROR':
      return <StatusIcon status={'Cancelled'} />
    default:
      return <StatusIcon status={'Sold'} />
  }
}

const AccountMyGolfClubInventoryDetails: React.FC<AccountMyGolfClubInventoryDetailsProps> = props => {
  const { golfItemId } = props

  const { hash } = useLocation()

  const isItemGolfClub = hash === '#golfClub'
  const itemType = hash.slice(1)

  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { data: userData, loading: userLoading } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const { data: golfClubData, loading: golfClubLoading } = useQuery<{ res: GolfClub }>(graphqlGolfClub.FetchGolfClub, {
    variables: { id: golfItemId },
    skip: !isItemGolfClub,
    fetchPolicy: 'network-only'
  })

  const { data: golfEquipmentData, loading: golfEquipmentLoading } = useQuery<{ res: GolfEquipment }>(
    graphqlGolfEquipment.FetchGolfEquipment,
    {
      variables: { id: golfItemId },
      skip: isItemGolfClub,
      fetchPolicy: 'network-only'
    }
  )

  const user = userData && userData.res
  const userAddressCards: AddressCard[] = user?.addressCards || []
  const defaultUserAddressCard: AddressCard = user?.defaultAddressCard || userAddressCards?.[0]

  if (!userLoading && !user) navigate('/home/')
  if (!user) return null

  const backUrl = '/account/store/my-golf-club-inventory'
  const golfClub = golfClubData && golfClubData.res
  const golfEquipment = golfEquipmentData && golfEquipmentData?.res

  const golfItem = golfClub || golfEquipment

  const golfItemLoading = golfClubLoading || golfEquipmentLoading

  if (!golfItemLoading && !golfItem) navigate(backUrl)
  if (!golfItem) return null

  const { id, insertedAt, expiresAt, price, status, brand, category } = golfItem

  const isGolfClub = (golfItem: GolfClub | GolfEquipment): golfItem is GolfClub => {
    return golfItem.hasOwnProperty('golfClubModel')
  }

  const golfItemModel = isGolfClub(golfItem) ? golfItem.golfClubModel : golfItem.golfEquipmentModel

  const avatar = golfItemModel?.avatar
  const title = golfItemModel?.title

  const isOnSaleGolfClub = status === 'ON_SALE'

  const columns: TableColumnType[] = [
    {
      title: 'Item',
      key: 'name',
      render(_, golfItem) {
        const title = isGolfClub(golfItem as GolfClub | GolfEquipment)
          ? golfItem.golfClubModel.title
          : golfItem.golfEquipmentModel.title

        return <>{title || '-'}</>
      }
    },
    {
      title: 'Brand',
      key: 'brand',
      render(_, row) {
        return <>{row?.brand?.title || '-'}</>
      }
    },
    {
      title: 'Category',
      key: 'category.title',
      render(_, row) {
        return <>{row?.category?.title || '-'}</>
      }
    },
    {
      title: 'price',
      key: 'price',
      render(price: number) {
        return <>{price ? '$' + price : '-'}</>
      }
    },
    {
      title: 'List Date',
      key: 'insertedAt',
      render(insertedAt: string) {
        const { formattedDate, formattedTime } = formatDateTimeString(insertedAt)
        return (
          <>
            {formattedDate}
            <br />
            {formattedTime}
          </>
        )
      }
    },
    {
      title: 'Expire Date',
      key: 'expiresAt',
      render(expired: string) {
        const { formattedDate, formattedTime } = formatDateTimeString(expired)
        return (
          <>
            {formattedDate}
            <br />
            {formattedTime}
          </>
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      render: renderStatus
    }
  ]

  const isGolfClubPage = golfItemModel.__typename === 'GolfClubModel'
  const golfItemType: ProductPageItemType = isGolfClubPage ? 'GolfClubModel' : 'GolfEquipmentModel'

  const renderMobile = () => {
    const { formattedDate: formattedUpdatedDate, formattedTime: formattedUpdatedTime } = formatDateTimeString(
      insertedAt
    )
    const { formattedDate: formattedExpiredDate, formattedTime: formattedExpiredTime } = formatDateTimeString(expiresAt)
    const golfClubModel = golfClub?.golfClubModel

    const optionNameByCategory = getGolfClubInfoByCategory(golfClubModel?.category)
    const dateData = [
      {
        label: 'List Date',
        value: `${formattedUpdatedDate} ${formattedUpdatedTime}`
      },
      {
        label: 'Expire Date',
        value: `${formattedExpiredDate} ${formattedExpiredTime}`
      }
    ]

    const data = [
      {
        label: 'Brand',
        value: brand?.title || '-'
      },
      {
        label: 'Category',
        value: category?.title || '-'
      },
      {
        label: 'Release year',
        value: (golfClubModel?.releaseYear + '' || '-').toString()
      },
      {
        label: 'Flex',
        value: golfClub?.golfClubType?.flex + '' || '-'
      },
      {
        label: 'Dexterity',
        value: golfClub?.golfClubType?.dexterity + '' || '-'
      },
      {
        label: 'Condition',
        value: golfClub?.golfClubType?.condition + '' || '-'
      },
      {
        label: optionNameByCategory.title,
        value: isGolfClub(golfItem) ? golfItem?.golfClubType?.option + optionNameByCategory?.options?.symbol : '-'
      }
    ]



    return (
      <MobileAccountDetailsLayout
        title={title}
        golfItemType={itemType}
        status={status + ''}
        avatar={avatar || ''}
        editLink={`/account/store/my-golf-club-inventory/${id}/edit-details${hash}`}
        titleLink={`/product/${golfItemModel?.slug}/${golfItemType}`}
        data={data}
        greyData={dateData}
        backLink={'/account/store/my-golf-club-inventory'}
        backLinkTitle={'My Golf Club Inventory'}
      >
        <div className={'account-my-golf-club-inventory-details-mobile-payment'}>
          <h4 className={'account-my-golf-club-inventory-details-mobile-payment-title uppercase'}>Payment</h4>
          <OrderPriceList addressCardId={defaultUserAddressCard.id} price={price} />
        </div>
        <div className={'account-my-golf-club-inventory-details-mobile-status'}>
          <div className={'uppercase'}>
            <strong>Status</strong>
          </div>
          {renderStatus(status + '')}
        </div>
      </MobileAccountDetailsLayout>
    )
  }




  return (
    <AccountLayout pageClassName={'my-golf-club-inventory-details'} hideLayout={isMobileScreen}>
      {isMobileScreen ? (
        renderMobile()
      ) : (
        <AccountDetailsLayout
          title={'My Golf Club Inventory'}
          details={{
            title: 'Item',
            text: title + ' ' + brand.title,
            link: `/product/${golfItemModel?.slug}/${golfItemType}`,
            date: insertedAt,
            expired: expiresAt
          }}
          backUrl={backUrl}
          renderHeaderButtons={() => {
            return (
              <>
                {isOnSaleGolfClub && (
                  <Button
                    size={'lg'}
                    type={'roundWithHoverBg'}
                    onClick={() => navigate(`/account/store/my-golf-club-inventory/${golfItemId}/edit-details${hash}`)}
                  >
                    Edit
                  </Button>
                )}
              </>
            )
          }}
          avatar={avatar}
          columns={columns}
          data={golfItem}
          loading={userLoading || golfItemLoading}
        >
          <OrderPriceList addressCardId={defaultUserAddressCard.id} price={price} />
          <div className={'account-my-golf-club-inventory-details-footer'}>
            <div className={'account-my-golf-club-inventory-details-footer-status'}>
              <div className={'uppercase'}>
                <strong>Status</strong>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div>{renderStatus(status + '')}</div>
              </div>
            </div>
          </div>
        </AccountDetailsLayout>
      )}
    </AccountLayout>
  )
}

export default AccountMyGolfClubInventoryDetails
